<script>
import { mapActions } from "vuex";

export default {
  props: {
    userList: {
      default: function () {
        return [];
      },
      type: Array,
    },
    type: {
      default: "",
      type: String,
    },
    size: {
      default: "mini",
      type: String,
    },
    field: {
      default: "id",
      type: String,
    },
    class_id: {
      default: "",
      type: String,
    },
    getGiftId: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {},
      tableData: [],
      id: "",
      loading: false,
      name: "",
      saveLoading: false,
    };
  },
  methods: {
    ...mapActions("gift", ["selectGift", "giving"]),
    async init() {
      try {
        this.loading = true;
        const { data } = await this.selectGift(this.name);
        this.tableData = data;
      } finally {
        this.loading = false;
      }
    },
    async selectGiftData() {
      try {
        if (this.id) {
          if (this.getGiftId) {
            let data = await this.tableData.find((v) => v.id === this.id);
            this.$emit("success", data);
          } else {
            this.saveLoading = true;
            let user_ids = this.userList.map((v) => v[this.field]);
            let form = {
              type: this.type,
              id: this.id,
              user_ids,
              class_id: this.class_id,
            };
            const { res_info } = await this.giving(form);
            if (res_info !== "ok") return;
            this.$message.success("赠送成功");
          }

          this.dialogVisible = false;
        } else {
          return this.$message.error("请选择礼物");
        }
      } finally {
        this.saveLoading = false;
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>

<template>
  <span class="gift">
    <el-button
      v-if="userList.length > 0 || getGiftId"
      :size="size"
      @click="dialogVisible = true"
      >选择礼品</el-button
    >
    <el-button v-else :size="size" @click="dialogVisible = true"
      >全部赠送</el-button
    >
    <el-dialog
      :visible.sync="dialogVisible"
      append-to-body
      title="请选择"
      width="30%"
    >
      <div>
        <div class="padding-t-sm padding-b-sm">
          <span class="margin-l-sm fl-l">
            <el-input v-model="name" placeholder="礼品名称" />
          </span>

          <span class="margin-l-sm fl-l">
            <el-button
              icon="el-icon-search"
              size="small"
              type="primary"
              @click="init"
              >搜索</el-button
            >
          </span>

          <div style="clear: both"></div>
        </div>
        <el-table
          v-loading="loading"
          :data="tableData"
          class="table"
          header-align="center"
          style="width: 100%"
        >
          <el-table-column align="center" label="选择" width="80">
            <template slot-scope="scope">
              <el-radio v-model="id" :label="scope.row.id">{{ "" }}</el-radio>
            </template>
          </el-table-column>

          <el-table-column align="center" label="礼品名称" prop="name" />
          <el-table-column align="center" label="学币" prop="credit" />
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="selectGiftData">赠送</el-button>
      </span>
    </el-dialog>
  </span>
</template>

<style scoped>
.table {
  max-height: 40vh;
  overflow: auto;
}

.table::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

/* 滚动条滑块 */
.table::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);
}
</style>